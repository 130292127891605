@import '/src/apps/web/styles/vendors/material/material-colors';

.bitf-dnd-droppable-area {
  background-color: $app-primary-light;
  border: solid 1px $app-primary-base;
  border-radius: 5px;
  height: 60px;
  transition: background-color ease-in 0.6s;

  &.bitf-dnd-file-is-dragging {
    background-color: $app-accent-light;
  }
}
