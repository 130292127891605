@use '@angular/material' as mat;

/**
  $headline-1: mat.define-typography-level(112px, 112px, 300, $font-family: 'Roboto', $letter-spacing: normal),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $headline-4: mat.define-typography-level(34px, 40px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $headline-5: mat.define-typography-level(24px, 32px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $headline-6: mat.define-typography-level(20px, 32px, 500, $font-family: 'Roboto', $letter-spacing: normal),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $body-1: mat.define-typography-level(14px, 24px, 500, $font-family: 'Roboto', $letter-spacing: normal),
  $body-2: mat.define-typography-level(14px, 18px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $caption: mat.define-typography-level(12px, 20px, 400, $font-family: 'Roboto', $letter-spacing: normal),
  $button: mat.define-typography-level(14px, 14px, 500, $font-family: 'Roboto', $letter-spacing: normal),
*/

// Typography
$app-typography-config: mat.define-typography-config(
  $font-family: Rubik,
  $headline-1: mat.define-typography-level(112px, 112px, 300),
  $headline-2: mat.define-typography-level(56px, 56px, 400),
  $headline-3: mat.define-typography-level(45px, 48px, 400),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 400),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400),
  $body-1: mat.define-typography-level(14px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 18px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
);
