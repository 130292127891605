.image-header {
  width: 130px;
}

.cells-vertical-align-top {
  .mat-cell {
    vertical-align: top;
    padding-top: 14px;
  }
}

table {
  &.border-on-cell-bottom {
    thead {
      tr {
        th.mat-header-cell {
          border-bottom: 1px solid $app-background;
        }
      }
    }
    tr {
      td.mat-cell {
        border-bottom: 1px solid $app-background;
      }
    }
  }
  &.without-first-last-column-padding {
    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 0;
    }
    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
      padding-left: 0;
    }
  }
}
