.user-account-button {
  mat-icon {
    position: relative;
    .online-indicator {
      position: absolute;
      top: 0px;
      right: 0px;
      border-radius: 10px;
      height: 8px;
      width: 8px;
      display: inline-block;
      &.online {
        background-color: green;
      }
      &.offline {
        background-color: red;
      }
    }
  }
}
