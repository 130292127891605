@import '/src/apps/web/styles/vendors/material/material-colors';

.text-primary-light {
  color: $app-primary-light;
}

.text-accent-base {
  color: $app-accent-base;
}

.blue-text {
  color: $primary-color;
}

.font-medium {
  font-weight: 500;
}

.text-gray-light {
  color: $app-grey;
}
